import React, {useCallback, useState} from 'react';
import {useAppDispatch, useAppSelector} from 'store/customer';
import {
    currentPageSet,
    selectBrowserType,
    BrowserType,
    selectMaterialFinishesEdge,
    selectMaterialFinishes,
    selectFinishes,
    selectFinishesEdge,
    materialFinishesEdgeSelect,
    materialFinishesSelect,
    clearFilters,
    selectHasDoors,
} from 'components/customer/AdvancedMaterials/store/materialSlice';
import {shallowEqual} from 'react-redux';
import {Menu} from 'components/customer/AdvancedMaterials/entity/Menu';
import styled from 'styled-components';
import {MiniRadio} from 'shared/components/Forms/MiniRadio';
import {Dropdown} from 'react-bootstrap';
import {Icon} from 'shared/helpers';
import {Filters} from 'components/customer/AdvancedMaterials/entity/Filters';
import {useSelectedMenu} from 'components/customer/AdvancedMaterials/helpers/useSelectedMenu';

export const Finishes = () => {
    const [show, setShow] = useState(false);

    const dispatch = useAppDispatch();
    const hasDoors = useAppSelector(selectHasDoors);
    const colorBrowserType = useAppSelector(selectBrowserType);
    const {selectedMenu} = useSelectedMenu();

    const materialFinishes = useAppSelector(
        selectedMenu == Menu.EDGE_MATERIAL
            ? selectMaterialFinishesEdge
            : selectMaterialFinishes,
        shallowEqual
    );
    const selectedFinishes = useAppSelector(
        selectedMenu == Menu.EDGE_MATERIAL
            ? selectFinishesEdge
            : selectFinishes,
        shallowEqual
    );

    const handleToggle = useCallback(
        (
            isOpen: boolean,
            metadata: {source: 'select' | 'click' | 'rootClose' | 'keydown'}
        ) => {
            if (metadata.source === 'select') {
                setShow(true);
            } else {
                setShow(isOpen);
            }
        },
        []
    );

    const handleSelect = useCallback(
        (finish: string, event: React.MouseEvent<HTMLDivElement>) => {
            event.preventDefault();
            event.stopPropagation();

            dispatch(currentPageSet(1));
            if (selectedMenu == Menu.EDGE_MATERIAL) {
                dispatch(materialFinishesEdgeSelect(finish));
            } else {
                dispatch(materialFinishesSelect(finish));
            }
        },
        [selectedMenu]
    );

    const handleClearAll = useCallback(
        (event: React.MouseEvent) => {
            event.stopPropagation();
            event.preventDefault();

            if (selectedMenu == Menu.EDGE_MATERIAL) {
                dispatch(clearFilters(Filters.FINISH_EDGE));
            } else {
                dispatch(clearFilters(Filters.FINISH));
            }
        },
        [selectedMenu]
    );

    if (
        colorBrowserType != BrowserType.BENCHTOP &&
        ((hasDoors && selectedMenu != Menu.DOOR_TYPE) || !hasDoors)
    ) {
        return (
            <Dropdown
                show={show}
                onToggle={handleToggle}
                onSelect={handleSelect}>
                <DropdownToggle variant="primary" id="dropdown-basic">
                    <div>
                        {selectedFinishes && selectedFinishes.length > 0
                            ? selectedFinishes
                                  .map((finish) => finish.name)
                                  .join(',')
                            : 'Select Finishes'}
                    </div>
                    <Icon iconName="Chevron-down.svg" />
                </DropdownToggle>

                <DropdownMenu>
                    <Dropdown.Item as="div">
                        <Button
                            onClick={handleClearAll}
                            disabled={
                                !(
                                    selectedFinishes &&
                                    selectedFinishes.length > 0
                                )
                            }>
                            Clear all filters
                        </Button>
                    </Dropdown.Item>

                    {materialFinishes
                        ? materialFinishes
                              .filter((finish) => finish.name != '')
                              .map((finish) => {
                                  return (
                                      <Dropdown.Item
                                          as="div"
                                          key={finish.id}
                                          eventKey={finish.id}>
                                          <MiniRadio
                                              key={finish.id}
                                              $checked={finish.selected}>
                                              {finish.name}
                                              <input type="checkbox" />
                                          </MiniRadio>
                                      </Dropdown.Item>
                                  );
                              })
                        : null}
                </DropdownMenu>
            </Dropdown>
        );
    }
};

const Button = styled.button`
    border: 0px;
    background: rgb(var(--secondary_colour));
    color: white;
    border-radius: 6px;
    font-weight: 500;
    padding: 4px 5px;

    &:hover {
        background: rgb(var(--primary_colour));
    }

    &:disabled {
        background: rgb(var(--secondary_colour));
        opacity: 0.5;
    }
`;

const DropdownToggle = styled(Dropdown.Toggle)`
    background: white !important;
    border: 2px solid rgb(var(--primary_colour)) !important;
    border-radius: 8px;
    color: gray !important;
    font-size: 1em;
    height: 38px;
    font-weight: 500;
    display: flex;
    align-items: center;
    width: 10vw;

    &:after {
        display: none;
    }

    > div {
        flex: 1;
        text-align: left;
        width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    > img {
        width: 15px;
        margin-left: 15px;
    }

    // Mobile devices
    @media screen and (max-width: 768px) and (orientation: portrait),
        screen and (max-width: 1024px) and (orientation: landscape) {
        width: 40vw;
    }
`;

const DropdownMenu = styled(Dropdown.Menu)`
    right: 0 !important;
    left: 0 !important;
    background: rgb(218, 222, 223);
    border: 0;
    box-shadow: 0 0 4px 0 #b9b5b5;
    min-width: max-content !important;
    max-height: 300px;
    overflow-y: auto;

    > div {
        font-size: 0.8em;
        font-weight: 500;
        color: #6b6f70 !important;
        padding: 0.01rem 1.5rem;
        white-space: initial;
        line-height: 1em;
        padding-top: 5px;
        padding-bottom: 5px;
        cursor: pointer;
    }

    > div:hover,
    a:focus {
        background: #c5c9ca;
    }
`;
