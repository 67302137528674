import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {useAppDispatch, useAppSelector} from 'store/customer';
import {
    BrowserType,
    edgeSet,
    materialSet,
    selectBrowserType,
    selectEdge,
    selectMaterial,
    selectMaterialByIndex,
    selectMaterialColumns,
    selectTotalRecords,
} from 'components/customer/AdvancedMaterials/store/materialSlice';
import styled from 'styled-components';
import {Finish} from 'components/customer/AdvancedMaterials/Materials/Finish';
import {shallowEqual} from 'react-redux';
import {Material as MaterialInterface} from 'components/customer/AdvancedMaterials/entity/Material';
import {parseHtmlString} from 'shared/helpers/HTMLParser';
import {Image} from 'shared/Image';
import {AppState} from 'store/customer/storeSetup';
import {Dropdown, Spinner} from 'react-bootstrap';
import {Menu as MenuI} from 'components/customer/AdvancedMaterials/entity/Menu';
import {useSelectedMenu} from 'components/customer/AdvancedMaterials/helpers/useSelectedMenu';
import {cloneDeep} from 'lodash';

interface MaterialProps {
    columnIndex: number;
    rowIndex: number;
    style: React.CSSProperties;
}

const checkIfImage = (image: string) => {
    if (typeof image == 'undefined' || image == null) {
        return false;
    }

    return /\.(gif|jpe?g|png)$/i.test(image.toLowerCase());
};

export const Material = ({columnIndex, rowIndex, style}: MaterialProps) => {
    const dispatch = useAppDispatch();
    const browserType = useAppSelector(selectBrowserType);
    const totalRecords = useAppSelector(selectTotalRecords, shallowEqual);
    const columns = useAppSelector(selectMaterialColumns);
    const index = useMemo(
        () => columnIndex + rowIndex * columns,
        [columnIndex, rowIndex, columns]
    );
    const materials = useAppSelector(
        (state: AppState) => selectMaterialByIndex(state, index),
        shallowEqual
    );
    const selectedMaterial = useAppSelector(selectMaterial, shallowEqual);
    const selectedMaterialEdge = useAppSelector(selectEdge, shallowEqual);
    const {selectedMenu} = useSelectedMenu();

    const [show, setShow] = useState(false);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [thickness, setThickness] = useState<number>();
    const timeout = useRef<NodeJS.Timeout>();

    const showFinishes = useCallback(() => setShow(true), []);
    const hideFinishes = useCallback(() => setShow(false), []);

    const material = useMemo(() => {
        if (materials && materials.length > 0) {
            const material = materials[0];
            let image = material.image;

            if (!checkIfImage(image)) {
                image = materials.find((material) =>
                    checkIfImage(material.image)
                )?.image;
            }

            return {
                image,
                name: material.name.replace(/\((.*?)\)/g, '<em>($1)</em>'),
            };
        }
    }, [materials]);

    const isSelected = useMemo(() => {
        if (
            materials &&
            materials.length > 0 &&
            selectedMaterial &&
            selectedMaterialEdge
        ) {
            return materials.some((material) => {
                if (selectedMenu == MenuI.MATERIAL) {
                    return material.id == selectedMaterial.id;
                } else {
                    return material.id == selectedMaterialEdge.id;
                }
            });
        }

        return false;
    }, [materials, selectedMaterial, selectedMenu, selectedMaterialEdge]);

    const setSelectedMaterial = useCallback(
        (selectedMaterial: MaterialInterface) => {
            const materialToSet = cloneDeep(selectedMaterial);
            if (materialToSet) {
                if (!checkIfImage(materialToSet.image)) {
                    materialToSet.image = material?.image;
                }

                if (
                    selectedMenu == MenuI.MATERIAL ||
                    selectedMenu == MenuI.THICKNESSES
                ) {
                    dispatch(materialSet(materialToSet));
                } else if (selectedMenu == MenuI.EDGE_MATERIAL) {
                    dispatch(edgeSet(materialToSet));
                }
            }
        },
        [selectedMenu, material]
    );

    const thicknesses = useMemo(() => {
        if (materials) {
            const thicknesses: number[] = [];
            materials.forEach((material) => {
                if (!thicknesses.includes(material.thickness)) {
                    thicknesses.push(material.thickness);
                }
            });

            // Sorting thicknesses in ascending order
            return thicknesses.sort((a, b) => a - b);
        }

        return [];
    }, [materials]);

    const handleImageClick = useCallback(() => {
        if (materials && materials.length > 0) {
            setSelectedMaterial(materials[0]);
        }
    }, [materials, setSelectedMaterial]);

    const handleThicknessChange = useCallback(
        (thickness: number) => (event: React.MouseEvent) => {
            event.preventDefault();
            setThickness(thickness);
        },
        []
    );

    const handleOnToggle = useCallback((isOpen: boolean) => {
        // when opening dropdown state is updated immediately
        if (isOpen) {
            if (timeout.current) {
                clearTimeout(timeout.current);
            }
            setDropdownOpen(isOpen);
        } else {
            // when closing dropdown we delay state update by
            // 2000ms so that we leave the ::after pseudo
            // on parent open just long enough.
            timeout.current = setTimeout(() => {
                setDropdownOpen(isOpen);
            }, 2000);
        }
    }, []);

    useEffect(() => {
        if (materials && selectedMaterial) {
            const selected = materials.find(
                (material) => material.thickness == selectedMaterial.thickness
            );

            if (selected) {
                setThickness(selected.thickness);
            } else {
                setThickness(materials[0].thickness);
            }
        }
    }, [materials, selectedMaterial]);

    if (material) {
        return (
            <div style={style}>
                <MaterialContainer>
                    <SubContainer
                        $selected={isSelected}
                        $dropdownOpen={dropdownOpen}
                        onMouseEnter={showFinishes}
                        onMouseLeave={hideFinishes}>
                        <ImageContainer onClick={handleImageClick}>
                            <MaterialImage src={material.image} />
                        </ImageContainer>
                        <Name>
                            <span>{parseHtmlString(material.name)}</span>

                            {browserType != BrowserType.BENCHTOP &&
                            thicknesses.length > 1 &&
                            show ? (
                                <Dropdown
                                    style={{alignSelf: 'flex-start'}}
                                    onToggle={handleOnToggle}>
                                    <Toggle variant="light" id="dropdown-basic">
                                        {thickness}mm
                                    </Toggle>

                                    <Menu align="right">
                                        {thicknesses.map((thickness) => {
                                            return (
                                                <Item
                                                    onClick={handleThicknessChange(
                                                        thickness
                                                    )}
                                                    key={thickness}>
                                                    {thickness}mm
                                                </Item>
                                            );
                                        })}
                                    </Menu>
                                </Dropdown>
                            ) : null}
                        </Name>
                        {show ? (
                            <Finish
                                thickness={thickness}
                                materials={materials}
                                selectedMaterial={
                                    selectedMenu == MenuI.MATERIAL
                                        ? selectedMaterial
                                        : selectedMaterialEdge
                                }
                                setSelectedMaterial={setSelectedMaterial}
                            />
                        ) : null}
                    </SubContainer>
                </MaterialContainer>
            </div>
        );
    } else if (index < totalRecords) {
        return (
            <LoadingMaterial style={style}>
                <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            </LoadingMaterial>
        );
    }
};

export const LoadingMaterial = styled.div`
    text-align: center;
    padding-top: 70px;
`;

const MaterialContainer = styled.div`
    box-sizing: border-box;
    position: relative;
    padding: 2px;
    height: 100%;
`;

export const SubContainer = styled.div<{
    $selected: boolean;
    $dropdownOpen: boolean;
    $previewOnly?: boolean;
}>`
    padding: 5px;
    border-radius: 8px;
    background: white;

    ${({$selected}) => {
        if ($selected) {
            return 'outline: 2px solid rgb(var(--primary_colour)) !important;';
        }
    }}

    ${({$previewOnly = false}) => {
        if (!$previewOnly) {
            return `
                &:hover {
                    position: absolute;
                    outline: 2px solid rgb(var(--secondary_colour));
                    top: 2px;
                    left: 2px;
                    right: 2px;
                    z-index: 1;
                }
            `;
        } else {
            return `
                &:hover {outline: 2px solid rgb(var(--secondary_colour));}
            `;
        }
    }}

    ${({$dropdownOpen, $previewOnly = false}) => {
        if ($dropdownOpen && !$previewOnly) {
            return `&:hover::after {
                content: '';
                position: absolute;
                left: 0;
                right: 0;
                height: 210px;
                background: transparent;
            }`;
        }
    }}
`;

export const ImageContainer = styled.div`
    height: 100px;
    border-radius: 8px;
    cursor: pointer;
    overflow: hidden;
`;

export const MaterialImage = styled(Image)`
    height: 200px;
    width: calc(100% + 20px) !important;
    margin-top: -50px;
    margin-left: -5px;
`;

export const Name = styled.div`
    font-weight: 500;
    color: #8d8d8d;
    margin: 5px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    // Mobile devices
    @media screen and (max-width: 768px) and (orientation: portrait),
        screen and (max-width: 1024px) and (orientation: landscape) {
        flex-direction: column;
        align-items: center;
        justify-content: space-around;

        > div {
            align-self: initial !important;
        }
    }
`;

const Toggle = styled(Dropdown.Toggle)`
    padding: 0px 5px;
    border: 1px solid rgb(var(--secondary_colour));
    font-size: 1em;
    font-weight: 400;
`;

const Menu = styled(Dropdown.Menu)`
    min-width: auto;
    padding: 8px 0;
    background: rgb(218, 222, 223);
`;

const Item = styled(Dropdown.Item)`
    background: rgb(218, 222, 223);
    padding: 0 15px;
    color: rgb(107, 111, 112);
    font-size: 0.95em;
    font-weight: 400;

    &:hover,
    &:focus,
    &:active {
        font-weight: 500;
        color: rgb(107, 111, 112);
        background: rgb(197, 201, 202);
    }
`;
