import styled from 'styled-components';
import React, {useLayoutEffect, useRef, useState} from 'react';
import {isDeviceSmall} from 'shared/helpers/DeviceSize';
import {connect} from 'react-redux';
import {
    Material,
    MaterialEdge,
    MaterialType,
} from 'components/customer/Materials/entity';
import {AppState} from 'store/customer/storeSetup';
import {getMaterial} from 'components/customer/Materials/store/selectors/materialSelector';
import {getEdge} from 'components/customer/Materials/store/selectors/edgeSelector';

interface ColorSwatchSelectedType {
    isExterior?: boolean;
    horizontal: boolean;
    isEdge?: boolean;
    smallThumbnail?: boolean;
    containerClass?: string;
    selectedColor?: Material;
    selectedEdge?: MaterialEdge;
    big?: boolean;
}

interface ThumbnailInterface {
    $size?: number;
    $isHorizontal?: boolean;
    $isSmallThumbnail?: boolean;
    $colorImage?: string;
    $edgeImage?: string;
    $isMobileDevice?: boolean;
}

const thumbnailSize = 130;
const mediumThumbnailSize = 110;
const smallThumbnailSize = 70;

const ColorSwatchSelectedComponent = ({
    horizontal = false,
    isEdge = false,
    containerClass = '.col',
    selectedColor,
    selectedEdge,
}: ColorSwatchSelectedType): JSX.Element => {
    const colorThumbnailRef = useRef<HTMLDivElement>();
    const isSmallDevice = isDeviceSmall();

    const [smallThumbnail, setSmallThumbnail] = useState(false);

    useLayoutEffect(() => {
        if (colorThumbnailRef.current) {
            let isThumbnailSmall = smallThumbnail;
            const container = colorThumbnailRef.current.closest(containerClass);

            if (container) {
                const containerDimension = container.getBoundingClientRect();
                const thumbnailDimension =
                    colorThumbnailRef.current.getBoundingClientRect();

                if (thumbnailDimension.width >= containerDimension.width - 40) {
                    isThumbnailSmall = true;
                }
            }

            if (isThumbnailSmall != smallThumbnail) {
                setSmallThumbnail(isThumbnailSmall);
            }
        }
    }, []);

    if (isEdge) {
        return (
            <ThumbnailEdgeContainer
                ref={colorThumbnailRef}
                $size={
                    isSmallDevice || smallThumbnail
                        ? isSmallDevice
                            ? mediumThumbnailSize
                            : smallThumbnailSize
                        : thumbnailSize
                }
                $edgeImage={selectedEdge ? selectedEdge.image : undefined}
                $isHorizontal={horizontal}>
                <ThumbnailColourEdgeRight />
            </ThumbnailEdgeContainer>
        );
    }

    return (
        <ThumbnailContainer
            ref={colorThumbnailRef}
            $size={
                isSmallDevice || smallThumbnail
                    ? isSmallDevice
                        ? mediumThumbnailSize
                        : smallThumbnailSize
                    : thumbnailSize
            }
            $colorImage={selectedColor ? selectedColor.image : undefined}
            $edgeImage={selectedEdge ? selectedEdge.image : undefined}
            $isHorizontal={horizontal}>
            <ThumbnailColourEdgeLeft />
            <ThumbnailColour />
            <ThumbnailColourEdgeRight />
        </ThumbnailContainer>
    );
};

export const ColorSwatchSelected = connect(
    (state: AppState, props: ColorSwatchSelectedType) => ({
        selectedColor: getMaterial(
            state,
            props.isExterior ? MaterialType.EXTERIOR : MaterialType.CARCASE
        ),
        selectedEdge: getEdge(
            state,
            props.isExterior ? MaterialType.EXTERIOR : MaterialType.CARCASE
        ),
    })
)(ColorSwatchSelectedComponent);

export const ThumbnailColour = styled.div`
    border: 2px solid black;
    box-sizing: border-box;
    padding: 0;
    background-repeat: no-repeat;
    background-size: cover;
`;

export const ThumbnailColourEdgeLeft = styled.div`
    width: 10px;
    border: 2px solid black;
    border-right: 0;
    top: 5px;
    left: -10px;
    bottom: -6px;
    position: absolute;
    transform: skewY(-45deg);
    border-bottom: 0;
    background-repeat: no-repeat;
    background-size: cover;
`;

export const ThumbnailColourEdgeRight = styled.div`
    height: 10px;
    border: 2px solid black;
    border-top: 0;
    left: -5px;
    bottom: -10px;
    right: 5px;
    position: absolute;
    transform: skewX(-45deg);
    background-repeat: no-repeat;
    background-size: cover;
`;

export const ThumbnailContainer = styled.div<ThumbnailInterface>`
    margin-top: 10px;
    margin-left: 15px;
    position: relative;
    width: ${(props) => props.$size}px;
    height: ${(props) => props.$size}px;

    ${ThumbnailColour} {
        width: ${(props) => props.$size}px;
        height: ${(props) => props.$size}px;
        background: ${(props) => `url("${props.$colorImage}")`};

        ${(props) => {
            if (props.$isHorizontal) {
                return `transform: rotate(90deg)`;
            }
        }}
    }

    ${ThumbnailColourEdgeLeft} {
        background: ${(props) => `url("${props.$edgeImage}")`};

        ${(props) => {
            if (props.$isHorizontal) {
                return `transform: rotate(90deg) skewX(45deg);
                top: ${
                    props.$isSmallThumbnail
                        ? 35
                        : props.$isMobileDevice
                        ? 55
                        : 65
                }px;
                left: ${
                    props.$isSmallThumbnail
                        ? -40
                        : props.$isMobileDevice
                        ? -60
                        : -70
                }px;
                border-top: 0;
                width: 100%;
                height: 10px;
                border-right: 2px solid black;
                border-bottom: 2px solid black;`;
            }
        }}
    }

    ${ThumbnailColourEdgeRight} {
        background: ${(props) => `url("${props.$edgeImage}")`};

        ${(props) => {
            if (props.$isHorizontal) {
                return `transform: rotate(90deg) skewY(45deg);
                top: ${
                    props.$isSmallThumbnail
                        ? 40
                        : props.$isMobileDevice
                        ? 60
                        : 70
                }px;
                left: ${
                    props.$isSmallThumbnail
                        ? 25
                        : props.$isMobileDevice
                        ? 45
                        : 54
                }px;
                height: inherit;
                width: 10px;
                border-top: 2px solid black;
                border-bottom: 2px solid black;
                border-left: 0;`;
            }
        }}
    }
`;

const ThumbnailEdgeContainer = styled(ThumbnailContainer)`
    height: 100%;
    width: ${(props) => props.$size}px;
    margin-left: 0;
    margin-top: 0;
    position: initial;

    ${ThumbnailColourEdgeRight} {
        position: relative;
        border: 2px solid black;
        height: 10px;
        width: ${(props) => props.$size}px;
        transform: skew(-45deg);
        top: initial;
        bottom: initial;
        right: initial;
        left: initial;
        margin-top: 50px;
        margin-left: 10px;
    }
`;
